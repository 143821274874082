<template>
  <div>
    <b-card title="Discount Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Discount Name">
                <b-form-input v-model="Discounts.name" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Discount code">
                <b-form-input v-model="Discounts.couponCode" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Valid Days">
                <v-select
                  multiple
                  v-model="Discounts.validDays"
                  label="name"
                  placeholder="Select valid days"
                  :options="validDays"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Hidden">
                <b-form-select
                  v-model="Discounts.isHide"
                  :options="visibilityOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-col class="align-self-center">
        <b-button @click="createDiscount" size="lg" block variant="primary"
          >Add Discount</b-button
        >
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      Discounts: {
        name: "",
        couponCode: "",
        validDays: [],
        isHide: false,
      },
      visibilityOptions: [
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      validDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    };
  },

  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createDiscount() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/hotel/discounts/create`,
          this.Discounts,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            `Discount has been created successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Error while creating the Discounts!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
